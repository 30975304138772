import { createTheme } from "@mui/material/styles";

export const customTheme = createTheme({
  typography: {
    fontFamily: `Poppins, "sans-serif"`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },

  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          border: "none",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          color: "#94a3b8",
          textAlign: "center",
          backgroundColor: "#333d48",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: "#fff",
          borderColor: "#fff",
          borderRadius: "10px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#fff",
          borderRadius: "5px",
          padding: "5px 10px",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: "0",
          flexShrink: "0",
          borderWidth: "0px 0px thin",
          borderColor: "#919eab3d",
          borderStyle: "dashed",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#212b36",
        },
      },
    },
  },
});
