import axios from "axios";
import { getCookie, setCookie, deleteCookie } from "../helpers/utils";
import _get from "lodash/get";

export const API_URL = process.env.REACT_APP_BASE_URL;

/***************** Cookie Handlers */
export const clearCookies = (history) => {
  deleteCookie("lo");
  deleteCookie("tk");
  history("/");
};

export const checkIfTokenExists = (history) => {
  const loggedOut = getCookie("lo");

  if (loggedOut === "false") {
    const token = getCookie("tk");

    if (token) {
      history("/dashboard");
    }
  }
};

export const triggerLogin = () => {
  setCookie("lo", false);
};

/************ Auhtentication ***************/
export const onFormSubmit = async (data, isLoggingSuccess, isLogginFailure) => {
  try {
    const res = await axios.post(`${API_URL}admin/login`, {
      email: data.email,
      password: data.password,
    });

    if (res.status === 200) {
      isLoggingSuccess(res);
    }
  } catch (err) {
    isLogginFailure(err);
  }
};

export const logOut = async (sessionToken, success, failure) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );

    if (res.status === 200) success();
  } catch (err) {
    failure(err);
  }
};

export const resetPassword = async (data, success, failure, sessionToken) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/changePassword`,
      {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );

    if (res.status === 200) success();
  } catch (err) {
    failure(err);
  }
};

/*************** Users ****************/
export const getUsers = async (getDetails, history, sessionToken, currentPage = 0, itemsPerPage = 5) => {
  try {
    const res = await axios.get(`${API_URL}admin/users`, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
      params: {
        skip: itemsPerPage * currentPage,
        limit: itemsPerPage * (currentPage + 1),
      },
    });

    if (res.status === 200) {
      getDetails(_get(res, "data.data.data"));
    }
  } catch (err) {
    const errorResponseType = _get(err, "response.data.responseType");
    if (errorResponseType === "TOKEN_ALREADY_EXPIRED") {
      clearCookies(history);
    }
  }
};
export const onUserEditFormSubmit = async (data, id, success, failure, sessionToken) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/addEditAppUser`,
      {
        userId: id,
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );

    if (res.status === 200) {
      success(res);
    }
  } catch (err) {
    failure(err);
  }
};

export const onUserAddFormSubmit = async (data, success, failure, sessionToken, addUser) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/addEditAppUser`,
      {
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );

    if (res.status === 200) {
      success();
    }
  } catch (err) {
    failure(err);
  }
};

export const blockUser = async (sessionToken, id, success, error) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/blockData`,
      {
        id: id,
        type: 3,
        status: true,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );
    if (res.status === 200) {
      success(res);
    }
  } catch (err) {
    error(err);
  }
};

export const unBlockUser = async (sessionToken, id, success, error) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/blockData`,
      {
        id: id,
        type: 3,
        status: false,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );
    if (res.status === 200) {
      success(res);
    }
  } catch (err) {
    error(err);
  }
};

/********************** Products *******************/
export const getProducts = async (getDetails, sessionToken) => {
  try {
    const res = await axios.get(`${API_URL}admin/listProducts`, {
      headers: {
        Authorization: "Bearer " + sessionToken,
      },
    });

    if (res.status === 200) getDetails(_get(res, "data.data.data"));
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log("req cancel");
    } else {
      console.log("req performed");
    }
  }
};
export const onProductsAdd = async (formData, success, failure, sessionToken) => {
  try {
    const res = await axios.post(`${API_URL}admin/addEditProducts`, formData, {
      headers: {
        Authorization: "Bearer " + sessionToken,
        "Content-Type": `multipart/form-data`,
      },
    });

    if (res.status === 200) success();
  } catch (err) {
    failure(err);
    console.log(err);
  }
};

export const onProductsEdit = async (data, success, failure, sessionToken) => {
  try {
    const res = await axios.post(`${API_URL}admin/addEditProducts`, data, {
      headers: {
        Authorization: "Bearer " + sessionToken,
      },
    });

    if (res.status === 200) {
      success(res);
    }
  } catch (err) {
    failure(err);
  }
};

export const blockProduct = async (sessionToken, id, success, error) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/blockData`,
      {
        id: id,
        type: 4,
        status: true,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );
    if (res.status === 200) {
      success(res);
    }
  } catch (err) {
    error(err);
  }
};

export const unBlockProduct = async (sessionToken, id, success, error) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/blockData`,
      {
        id: id,
        type: 4,
        status: false,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );
    if (res.status === 200) {
      success(res);
    }
  } catch (err) {
    error(err);
  }
};
/******************* Admins ******************/
export const getAdmins = async (getDetails, sessionToken) => {
  try {
    const res = await axios.get(`${API_URL}admin/listAdmins`, {
      headers: {
        Authorization: "Bearer " + sessionToken,
      },
    });

    if (res.status === 200) {
      getDetails(_get(res, "data.data.data"));
    }
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log("req cancel");
    } else {
      console.log("req performed");
    }
  }
};

/***************** Category and Sub Category ******/
export const getCategory = async (getDetails, sessionToken) => {
  try {
    const res = await axios.get(`${API_URL}admin/listData`, {
      headers: {
        Authorization: "Bearer " + sessionToken,
      },
      params: {
        type: 1,
      },
    });

    if (res.status === 200) getDetails(_get(res, "data.data.data"));
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log("req cancel");
    } else {
      console.log("req performed");
    }
  }
};

export const onCategoryAdd = async (data, success, failure, sessionToken) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/addEditCategory`,
      {
        name: data.name,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );

    if (res.status === 200) success();
  } catch (err) {
    failure(err);
  }
};

export const onCategoryEdit = async (data, id, success, failure, sessionToken) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/addEditCategory`,
      {
        categoryId: id,
        name: data.name,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );

    if (res.status === 200) {
      success(res);
    }
  } catch (err) {
    failure(err);
  }
};
export const blockCategory = async (sessionToken, id, success, error) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/blockData`,
      {
        id: id,
        type: 5,
        status: true,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );
    if (res.status === 200) {
      success(res);
    }
  } catch (err) {
    error(err);
  }
};

export const unBlockCategory = async (sessionToken, id, success, error) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/blockData`,
      {
        id: id,
        type: 5,
        status: false,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );
    if (res.status === 200) {
      success(res);
    }
  } catch (err) {
    error(err);
  }
};
export const getSubCategory = async (getDetails, sessionToken) => {
  try {
    const res = await axios.get(`${API_URL}admin/listData`, {
      headers: {
        Authorization: "Bearer " + sessionToken,
      },
      params: {
        type: 2,
      },
    });

    if (res.status === 200) getDetails(_get(res, "data.data.data"));
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log("req cancel");
    } else {
      console.log("req performed");
    }
  }
};
export const onSubCategoryAdd = async (data, success, failure, sessionToken) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/addEditSubCategory`,
      {
        name: data.name,
        categoryId: data.categoryId,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );

    if (res.status === 200) success();
  } catch (err) {
    failure(err);
  }
};
export const onSubCategoryEdit = async (formData, success, failure, sessionToken) => {
  try {
    const res = await axios.post(`${API_URL}admin/addEditSubCategory`, formData, {
      headers: {
        Authorization: "Bearer " + sessionToken,
        "Content-Type": `multipart/form-data`,
      },
    });

    if (res.status === 200) {
      success();
    }
  } catch (err) {
    failure(err);
  }
};
export const blockSubCategory = async (sessionToken, id, success, error) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/blockData`,
      {
        id: id,
        type: 6,
        status: true,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );
    if (res.status === 200) {
      success(res);
    }
  } catch (err) {
    error(err);
  }
};

export const unBlockSubCategory = async (sessionToken, id, success, error) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/blockData`,
      {
        id: id,
        type: 6,
        status: false,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );
    if (res.status === 200) {
      success(res);
    }
  } catch (err) {
    error(err);
  }
};

/*************Blog Add - Edit */
export const getBlogPosts = async (getDetails, sessionToken) => {
  try {
    const res = await axios.get(`${API_URL}admin/listBlogPosts`, {
      headers: {
        Authorization: "Bearer " + sessionToken,
      },
      params: {
        skip: 0,
        limit: 10,
      },
    });

    if (res.status === 200) getDetails(_get(res, "data.data.data"));
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log("req cancel");
    } else {
      console.log("req performed");
    }
  }
};
export const onBlogPostAdd = async (formData, success, failure, sessionToken) => {
  try {
    const res = await axios.post(`${API_URL}admin/addEditBlogPost`, formData, {
      headers: {
        Authorization: "Bearer " + sessionToken,
        "Content-Type": `multipart/form-data`,
      },
    });

    
    if (res.status === 200) success();
  } catch (err) {
    failure(err);
    console.log(err);
  }
};

export const onBlogPostEdit = async (formData, success, failure, sessionToken) => {
  try {
    const res = await axios.post(`${API_URL}admin/addEditBlogPost`, formData, {
      headers: {
        Authorization: "Bearer " + sessionToken,
        "Content-Type": `multipart/form-data`,
      },
    });

    console.log(res);

    if (res.status === 200) {
      success(res);
    }
  } catch (err) {
    failure(err);
  }
};

export const blockBlogPost = async (sessionToken, id, success, error) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/blockData`,
      {
        id: id,
        type: 7,
        status: true,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );
    if (res.status === 200) {
      success(res);
    }
  } catch (err) {
    error(err);
  }
};

export const unBlockBlogPost = async (sessionToken, id, success, error) => {
  try {
    const res = await axios.post(
      `${API_URL}admin/blockData`,
      {
        id: id,
        type: 7,
        status: false,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        },
      }
    );

    if (res.status === 200) {
      success(res);
    }
  } catch (err) {
    error(err);
  }
};
// Requests
export const getVideoRequests = async (sessionToken) => {
  try {
    const res = await axios.get(`${API_URL}admin/v2/list-requests`, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
      // params: {
      //   skip: itemsPerPage * currentPage,
      //   limit: itemsPerPage * (currentPage + 1),
      // },
    });

    if (res.status === 200) {
      return _get(res, "data.data.data");
    }
  } catch (err) {
    const errorResponseType = _get(err, "response.data.responseType");
    if (errorResponseType === "TOKEN_ALREADY_EXPIRED") {
      // clearCookies(history);
    }
  }
};

export const getVideoRequestsDetails = async (sessionToken, id) => {
  try {
    const res = await axios.get(`${API_URL}admin/v2/list-requests?id=${id}`, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
      // params: {
      //   skip: itemsPerPage * currentPage,
      //   limit: itemsPerPage * (currentPage + 1),
      // },
    });

    if (res.status === 200) {
      return _get(res, "data.data.data");
    }
  } catch (err) {
    const errorResponseType = _get(err, "response.data.responseType");
    if (errorResponseType === "TOKEN_ALREADY_EXPIRED") {
      // clearCookies(history);
    }
  }
};
export const updateVideoRequest = async (sessionToken, payload) => {
  try {
    const res = await axios.put(`${API_URL}admin/v2/update-request`,payload, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
      // params: {
      //   skip: itemsPerPage * currentPage,
      //   limit: itemsPerPage * (currentPage + 1),
      // },
    });

    if (res.status === 200) {
      return {"status": "success"}
    }
  } catch (err) {
    const errorResponseType = _get(err, "response.data.responseType");
    if (errorResponseType === "TOKEN_ALREADY_EXPIRED") {
      // clearCookies(history);
    }
  }
};