import { useState, createContext, useContext } from "react";
import { useLocation } from "react-router-dom";

export const AdminLayoutContext = createContext();

export const AdminLayoutProvider = ({ children }) => {
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const initialSelected = pathParts[pathParts.length - 1];

  const [selected, setSelected] = useState(initialSelected || "dashboard");
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <AdminLayoutContext.Provider
      value={{
        isCollapsed,
        setIsCollapsed,
        selected,
        setSelected,
      }}
    >
      {children}
    </AdminLayoutContext.Provider>
  );
};

export const useAdminLayoutContext = () => {
  return useContext(AdminLayoutContext);
};
