import { MenuItem, menuClasses } from "react-pro-sidebar";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
const menuItemButtonStyles = {
  color: "#919eab !important",
  borderRadius: "10px",
  mb: "4px",
  "&:hover": {
    backgroundColor: "#919eab14 !important",
  },
};

const menuItemActiveStyles = {
  color: "#76b0f1 !important",
  backgroundColor: "#172740 !important",
  "&:hover": {
    color: "#76b0f1 !important",
    backgroundColor: "#172740 !important",
  },
};

export const Item = ({ title, to, icon, selected, setSelected, value }) => {
  return (
    <MenuItem
      active={selected === value}
      rootStyles={{
        ["." + menuClasses.button]: menuItemButtonStyles,
        ["." + menuClasses.active]: menuItemActiveStyles,
      }}
      onClick={() => {
        setSelected(value);
      }}
      icon={icon}
      component={<Link to={to} />}
    >
      <Typography
        sx={{
          fontSize: "14px",
        }}
      >
        {title}
      </Typography>
    </MenuItem>
  );
};
