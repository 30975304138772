export const setCookie = (cname, cvalue, exdays = 30) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const deleteCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

// // export function jsonToFormData(json, fileFieldName, file) {
// //   const formData = new FormData();

// //   function appendFormData(data, rootKey) {
// //     if (Array.isArray(data)) {
// //       data.forEach((value, index) => {
// //         appendFormData(value, `${rootKey}[${index}]`);
// //       });
// //     } else if (typeof data === "object" && data !== null) {
// //       Object.keys(data).forEach((key) => {
// //         appendFormData(data[key], rootKey ? `${rootKey}.${key}` : key);
// //       });
// //     } else {
// //       formData.append(rootKey, data);
// //     }
// //   }

// //   appendFormData(json);

// //   if (file) {
// //     formData.append(fileFieldName, file);
// //   }

// //   return formData;
// // }

// // export function jsonToFormData(json) {
// //   const formData = new FormData();

// //   const appendToFormData = (data, parentKey) => {
// //     if (Array.isArray(data)) {
// //       data.forEach((value, index) => {
// //         const key = `${parentKey}[${index}]`;
// //         appendToFormData(value, key);
// //       });
// //     } else if (typeof data === 'object' && data !== null) {
// //       Object.entries(data).forEach(([key, value]) => {
// //         if (Array.isArray(value) && value.every(item => typeof item === 'string')) {
// //           value.forEach((item, index) => {
// //             const arrayKey = `${parentKey}[${key}][${index}]`;
// //             formData.append(arrayKey, item);
// //           });
// //         } else if (value instanceof File) {
// //           formData.append(parentKey, value);
// //         } else {
// //           if (parentKey) {
// //             key = `${parentKey}[${key}]`;
// //           }
// //           appendToFormData(value, key);
// //         }
// //       });
// //     } else {
// //       formData.append(parentKey, data);
// //     }
// //   };

// //   appendToFormData(json, null);

// //   return formData;
// // }

// export function jsonToFormData(json) {
//   const formData = new FormData();

//   const appendToFormData = (data, parentKey) => {
//     if (Array.isArray(data)) {
//       data.forEach((value, index) => {
//         const key = `${parentKey}[${index}]`;
//         appendToFormData(value, key);
//       });
//     } else if (typeof data === 'object' && data !== null) {
//       Object.entries(data).forEach(([key, value]) => {
//         if (value instanceof File) {
//           formData.append(parentKey, value);
//         } else if (Array.isArray(value)) {
//           value.forEach((item, idx) => {
//             const itemKey = `${parentKey}[${key}][${idx}]`;
//             appendToFormData(item, itemKey);
//           });
//         } else {
//           if (parentKey) {
//             key = `${parentKey}[${key}]`;
//           }
//           appendToFormData(value, key);
//         }
//       });
//     } else {
//       formData.append(parentKey, data);
//     }
//   };

//   appendToFormData(json, null);

//   return formData;
// }

export function jsonToFormData(json) {
  const formData = new FormData();

  const appendToFormData = (data, parentKey) => {
    if (Array.isArray(data)) {
      const stringifiedValue = JSON.stringify(data);
      formData.append(parentKey, stringifiedValue);
    } else if (typeof data === "object" && data !== null) {
      Object.entries(data).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(parentKey, value);
        } else {
          if (parentKey) {
            key = `${parentKey}[${key}]`;
          }
          appendToFormData(value, key);
        }
      });
    } else {
      formData.append(parentKey, data);
    }
  };

  appendToFormData(json, null);

  return formData;
}
