/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
/***************Sidebar Imports********/
import { Sidebar, Menu, MenuItem, useProSidebar, menuClasses } from "react-pro-sidebar";
import AvatarPerson from "../../assets/images/avatar_default.jpg";
import { Box, Typography, IconButton } from "@mui/material";
/*************MUI Icons ************/
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
// import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
// import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

import { useAdminLayoutContext } from "../../context/AdminLayoutContext";

/************Custom ELements********* */
import { CustomCollapsedMenuTypography, CustomMenuTypography } from "./CustomMenuTypography";
import { Item } from "./CustomMenuItem";
/**************Logo************* */
import logo from "../../assets/images/logo.png";
import mobile_logo from "../../assets/images/mobile_logo.png";

const SideBar = () => {
  const { isCollapsed, setIsCollapsed, selected, setSelected } = useAdminLayoutContext();
  const { collapseSidebar, toggleSidebar, broken } = useProSidebar();

  return (
    <Box
      sx={{
        height: "100vh",
        position: "fixed",
        zIndex: 1000,
        padding: "0 0 60px 0",
      }}
    >
      <div className="relative top-2">
        {broken && (
          <IconButton onClick={() => toggleSidebar()}>
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </div>
      <Sidebar
        breakPoint="lg"
        backgroundColor="#212b36"
        rootStyles={{
          border: "none",
          height: "100vh",
        }}
        collapsed={isCollapsed}
      >
        <Menu
          iconShape="square"
          rootStyles={{
            background: "#212b36 !important",
          }}
        >
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => {
              setIsCollapsed(!isCollapsed);
              collapseSidebar();
            }}
            icon={isCollapsed ? <img src={mobile_logo} alt="logo" className="w-10" /> : null}
            style={{
              margin: "10px 0",
            }}
            rootStyles={{
              ["." + menuClasses.button]: {
                backgroundColor: "transparent !important",
                color: "#919eab !important",
                "&:hover": {
                  backgroundColor: "transparent !important",
                },
              },
            }}
          >
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                <img src={logo} alt="logo" className="w-[8rem]" />
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="80px"
                  height="80px"
                  src={AvatarPerson}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography variant="h5" color={"#dfe2e7"} fontWeight="bold" sx={{ m: "10px 0 0 0" }}>
                  Animo Admin
                </Typography>
                {/* <Typography variant="h6" color={"#a6cdf4"}>
                  admin@gmail.com
                </Typography> */}
              </Box>
            </Box>
          )}

          <Box padding={isCollapsed ? null : "0% 5%"}>
            <Item
              title="Dashboard"
              value="dashboard"
              to="/admin/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
{/* 
            {!isCollapsed ? (
              <CustomCollapsedMenuTypography title={"Manage Users"} />
            ) : (
              <CustomMenuTypography title={"Manage Users"} />
            )} */}
            
            <Item
              title="All Users"
              value="user"
              to="/admin/user"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="All Requests"
              value="request"
              to="/admin/request"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* {!isCollapsed ? (
              <CustomCollapsedMenuTypography title={"Products"} />
            ) : (
              <CustomMenuTypography title={"Products"} />
            )}
            <Item
              title="All Products"
              value="product"
              to="/admin/product"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {!isCollapsed ? (
              <CustomCollapsedMenuTypography title={"Product Categories"} />
            ) : (
              <CustomMenuTypography title={"Product Categories"} />
            )}
            <Item
              title="Category"
              value={"Category"}
              to="/admin/Category"
              icon={<ShoppingCartIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Sub Category"
              to="/admin/subCategory"
              value="subCategory"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {!isCollapsed ? <CustomCollapsedMenuTypography title={"Blog"} /> : <CustomMenuTypography title={"Blog"} />}
            <Item
              title="Blog"
              value="blog"
              to="/admin/blog"
              icon={<RssFeedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default SideBar;
