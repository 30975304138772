import { Navigate } from "react-router-dom";
import { getCookie } from "../../helpers/utils";

export default function GuestGuard({ children }) {
  /*** isSessionTokenPresent is a string so if present it will return true hence outlet */
  const isSessionTokenPresent = getCookie("lo");

  if (isSessionTokenPresent) {
    return <Navigate to={"/admin/dashboard"} />;
  }

  return <> {children} </>;
}
