import { Suspense, lazy } from "react";
import Loader from "../components/Loader";

// components
const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

//code-splitting dynamic import
export const LoginPage = Loadable(lazy(() => import("../pages/LoginPage")));
export const ResetPasswordPage = Loadable(lazy(() => import("../pages/ResetPasswordPage")));
export const AdminDashboardPage = Loadable(lazy(() => import("../pages/AdminDashboardPage")));
export const UserPage = Loadable(lazy(() => import("../pages/UserPage")));
export const ProductPage = Loadable(lazy(() => import("../pages/ProductPage")));
export const CategoryPage = Loadable(lazy(() => import("../pages/CategoryPage")));
export const SubCategoryPage = Loadable(lazy(() => import("../pages/SubCategoryPage")));
export const BlogPostPage = Loadable(lazy(() => import("../pages/BlogPostPage")));
export const Page404 = Loadable(lazy(() => import("../pages/Page404")));
export const RequestPage = Loadable(lazy(() => import("../pages/RequestPage")));
export const RequestDetailPage = Loadable(lazy(() => import("../pages/RequestDetailPage")));
