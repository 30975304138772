import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "../../helpers/utils";
import LoginPage from "../../pages/LoginPage";

export default function AuthGuard({ children }) {
  /*** isAuthenticated is  a string so if present it will return true hence outlet */
  const isAuthenticated = getCookie("lo");

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <LoginPage />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);

    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
