import { BrowserRouter } from "react-router-dom";
import { Provider as UserContextProvider } from "./context/dataContext";
import { ThemeProvider } from "@mui/material/styles";
import { CustomSnackBarProvider } from "./components/Snackbar/SnackbarProvider";
import { ProSidebarProvider } from "react-pro-sidebar";
import { customTheme } from "./themes/customTheme";
import { FormProvider, useForm } from "react-hook-form";
import { AdminLayoutProvider } from "./context/AdminLayoutContext";

import Router from "./routes";

const App = () => {
  const methods = useForm();
  return (
    <BrowserRouter>
      <ProSidebarProvider>
        <ThemeProvider theme={customTheme}>
          <AdminLayoutProvider>
            <CustomSnackBarProvider>
              <UserContextProvider>
                <FormProvider {...methods}>
                  <Router />
                </FormProvider>
              </UserContextProvider>
            </CustomSnackBarProvider>
          </AdminLayoutProvider>
        </ThemeProvider>
      </ProSidebarProvider>
    </BrowserRouter>
  );
};

export default App;
