import createDataContext from "./createDataContext";

const initialState = {
  users: [],
  category: [],
  subCategory: [],
  products: [],
  blogs: [],
  requests: []
};

const dataReducer = (state, action) => {
  switch (action.type) {
    case "GET_USER_LIST":
      return { ...state, users: action.payload };
    case "GET_CATEGORY_LIST":
      return { ...state, category: action.payload };
    case "GET_SUB_CATEGORY_LIST":
      return { ...state, subCategory: action.payload };
    case "GET_PRODUCT_LIST":
      return { ...state, products: action.payload };
    case "GET_BLOG_POST_LIST":
      return { ...state, blogs: action.payload };
    case "GET_REQUEST":
      return { ...state, blogs: action.payload };

    default:
      return state;
  }
};

const getUserList = (dispatch) => (data) => {
  dispatch({ type: "GET_USER_LIST", payload: data });
};

const getCategoryList = (dispatch) => (data) => {
  dispatch({ type: "GET_CATEGORY_LIST", payload: data });
};

const getProductList = (dispatch) => (data) => {
  dispatch({ type: "GET_PRODUCT_LIST", payload: data });
};

const getSubCategoryList = (dispatch) => (data) => {
  dispatch({ type: "GET_SUB_CATEGORY_LIST", payload: data });
};

const getBlogPostList = (dispatch) => (data) => {
  dispatch({ type: "GET_BLOG_POST_LIST", payload: data });
};

const getVideoRequestsList = (dispatch) => (data) => {
  dispatch({ type: "GET_REQUEST", payload: data });
};

export const { Provider, Context } = createDataContext(
  dataReducer,
  {
    getUserList,
    getCategoryList,
    getProductList,
    getSubCategoryList,
    getBlogPostList,
    getVideoRequestsList
  },
  initialState
);
