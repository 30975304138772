import { Navigate, useRoutes } from "react-router-dom";

import AdminLayout from "../layout/AdminLayout";

import {
  LoginPage,
  UserPage,
  ResetPasswordPage,
  AdminDashboardPage,
  Page404,
  SubCategoryPage,
  CategoryPage,
  ProductPage,
  BlogPostPage,
  RequestPage,
  RequestDetailPage
} from "./elements";
import GuestGuard from "../components/Auth/GuestGuard";
import AuthGuard from "../components/Auth/AuthGuard";

export default function Router() {
  return useRoutes([
    {
      path: "",
      children: [
        { path: "", element: <Navigate to="/login" /> },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        { path: "reset-password", element: <ResetPasswordPage /> },
      ],
    },
    {
      path: "admin",
      element: (
        <AuthGuard>
          <AdminLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to="/admin/dashboard" replace />,
          index: true,
        },
        { path: "dashboard", element: <AdminDashboardPage /> },
        { path: "request", element: <RequestPage /> },
        { path: "request/:id", element: <RequestDetailPage /> },
        { path: "user", element: <UserPage /> },
        // { path: "product", element: <ProductPage /> },
        // { path: "category", element: <CategoryPage /> },
        // { path: "subCategory", element: <SubCategoryPage /> },
        // { path: "blog", element: <BlogPostPage /> },
      ],
    },
    {
      path: "404",
      element: (
        <AuthGuard>
          <Page404 />
        </AuthGuard>
      ),
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
