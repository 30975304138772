import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import _get from "lodash/get";
import { Button, Stack, IconButton, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { setCookie } from "../../helpers/utils";
import {
  triggerLogin,
  checkIfTokenExists,
  onFormSubmit,
} from "../../services/apis";
import { Navigate, useNavigate } from "react-router-dom";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import AnimoLogo from "../../assets/images/logo.png";

const Login = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const { enqueueSnackbar } = useSnackbar();

  const [success, setSuccess] = useState(false);
  const history = useNavigate();

  const isLoggingSuccess = (response) => {
    const token = _get(response, "data.data.accessToken");
    setCookie("tk", token);
    triggerLogin();
    enqueueSnackbar("SuccessFully Logged In", {
      variant: "success",
    });
    setSuccess(!success);
  };

  const isLogginFailure = (error) => {
    const errorMessage = _get(error, "response.data.message");

    enqueueSnackbar(errorMessage, {
      variant: "error",
    });
  };

  const { register, handleSubmit } = useForm({
    shouldUseNativeValidation: true,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  useEffect(() => {
    checkIfTokenExists(history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onFormSubmit(data, isLoggingSuccess, isLogginFailure);
      })}
      className="mx-auto w-full p-4 max-w-[400px] min-h-[100vh] flex flex-col text-center justify-center items-center"
    >
      <Stack spacing={2} sx={{ position: "relative" }}>
        <div className="flex justify-center">
          <img src={AnimoLogo} alt="Logo" className="w-24" />
        </div>
        <div className="text-3xl font-bold text-[#4297ff] text-center">
          Sign in to Animo
        </div>
        <TextField
          label={"Email address"}
          size="medium"
          autoComplete="off"
          onChange={handleChange("email")}
          values={values.email}
          {...register("email", { required: true })}
        />

        <TextField
          label={"Password"}
          size="medium"
          onChange={handleChange("password")}
          values={values.password}
          {...register("password", { required: true })}
          type={values.showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={handleClickShowPassword}
                sx={{ color: "#72778b" }}
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
        />
        <Button
          variant="contained"
          type="submit"
          sx={{
            bgcolor: "#2c76ff",
            color: "#ffffff",
            textTransform: "lowercase",
            fontSize: "16px",
            padding: "8px",
            borderRadius: "8px",
            minWidth: "320px",
            fontFamily: "Poppins",
            "&:hover": {
              bgcolor: "#2564d9",
            },
          }}
        >
          Login
        </Button>
        {success && <Navigate to="/admin/dashboard" />}
      </Stack>
    </form>
  );
};

export default Login;
