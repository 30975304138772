import SnackbarCloseButton from "./SnackbarCloseButton";
import { SnackbarProvider } from "notistack";
export const CustomSnackBarProvider = ({ children }) => {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transitionDuration={{ enter: 250, exit: 200 }}
      autoHideDuration={3000}
      action={(snackbarKey) => (
        <SnackbarCloseButton snackbarKey={snackbarKey} />
      )}
    >
      {children}
    </SnackbarProvider>
  );
};
