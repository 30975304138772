import React from "react";

import Login from "../components/Auth/Login";

const LoginPage = () => {
  return (
    <>
      <div className="flex items-center flex-col gap-2 h-[100vh] bg-[#161c24]">
        <Login />
      </div>
    </>
  );
};

export default LoginPage;
