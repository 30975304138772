import { Stack } from "@mui/material";
import { Outlet } from "react-router-dom";

import SideBar from "../components/Sidebar";

const AdminLayout = () => {
  return (
    <Stack direction={"row"}>
      <SideBar />
      <Outlet />
    </Stack>
  );
};

export default AdminLayout;
